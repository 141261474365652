import throttle from "./throttle.js";

const triggers = ["scroll"];

const InfinityScroll = {
	name: "infinity-scroll",
	props: {
		handler: {
			type: Function,
			required: true
		},
		threshold: {
			type: Number,
			default: 100
		},
		enabled: {
			type: Boolean,
			default: false
		},
	},
	mounted() {
		this.checkInView();
	},
	methods: {
		checkInView() {
			var t = this;
			const execute = () => {
				if (!t.enabled) return;

				const scrollY = window.scrollY;
				const visible = document.documentElement.clientHeight
				const pageHeight = document.documentElement.scrollHeight
				const bottomOfPage = visible + scrollY + t.threshold >= pageHeight
				const inView = bottomOfPage || pageHeight < visible

				if (t.handler && inView) {
					t.handler();
				}
			};

			t._scrollContainer = window;

			// Add event listeners
			t.check = throttle(execute, 200);
			triggers.forEach(event =>
				t._scrollContainer.addEventListener(event, t.check)
			);
		}
	},
	render(h) {
		return h(
			"div",
			{
				ref: "scroll"
			},
			this.$slots.default
		);
	},
	beforeDestroy() {
		triggers.forEach(event =>
			this._scrollContainer.removeEventListener(event, this.check)
		);
	}
};

export default InfinityScroll;